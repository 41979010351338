import React, {Fragment, useEffect, useRef, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import prison_boundaries from "./data/Prison_Boundaries.geojson";
import MapGL, {Source, Layer, FeatureState} from '@urbica/react-map-gl';
import * as turf from "@turf/turf";

const useStyles = makeStyles( {
  root: {
    display: "flex",
    height: "100vh"
  },
  container: {
    margin: 0,
    flex: 1
  }
})
const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiZWxldmFuIiwiYSI6ImM0akE0cU0ifQ.qHJlb8uJetbIzukKLdk4BQ"
const App = () => {

  const [viewport, setViewport] = useState({
    latitude: 37,
    longitude: -122,
    zoom: 10,
  });
  const [boundaries, setBoundaries] = useState();
  const [centerpoints, setCenterpoints] = useState();
  const [hoveredStateId, setHoveredStateId] = useState(null);
  const classes = useStyles();

  const onHover = (event) => {
    if (event.features.length > 0) {
      const nextHoveredStateId = event.features[0].id;
      if (hoveredStateId !== nextHoveredStateId) {
        setHoveredStateId(nextHoveredStateId);
        console.log(nextHoveredStateId);

      }
    }
  };

  const onLeave = () => {
    if (hoveredStateId) {
      setHoveredStateId(null);
    }
  };
  useEffect(() => {
    fetch(prison_boundaries).then(response => {
      response.json().then(data=>{
        setBoundaries(data);
        const points = turf.featureCollection(data.features.map((feature => {
          const pt = turf.center(feature)
          pt.properties = feature.properties;
          pt.id = feature.properties.FACILITYID;
          return pt;
        })));
        setCenterpoints(points);
      });
    })
  }, [])

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container  direction={"column"}>
        <Grid container item xs={12} >
          <MapGL
            onViewportChange={setViewport}
           className={classes.container}
           mapStyle='mapbox://styles/elevan/ckchyopge1rjr1inttmz9egih'
           accessToken={MAPBOX_ACCESS_TOKEN}
           latitude={viewport.latitude}
           longitude={viewport.longitude}
           zoom={viewport.zoom}
           >
            <Source id='prison_boundaries' type='geojson' data={boundaries} />
            <Source id='prison_points' type='geojson' data={centerpoints} />

            <Layer
              visible={viewport.zoom > 8}
              id='boundaries'
              type='fill'
              source='prison_boundaries'
              paint={{
                'fill-color': '#FF5C10',
                'fill-outline-color': '#cb2d2d',
                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5]

              }}
              onHover={onHover}
              onLeave={onLeave}
            />
            <Layer
              id='capacities'
              type='circle'
              source='prison_points'
              paint={{
                'circle-radius': [
                  "/", ['number', ['get', 'CAPACITY']],  1000/viewport.zoom
                ],
                'circle-color': 'black',
                'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5]
              }}
              onHover={onHover}
              onLeave={onLeave}
            />
            <Layer
              id='population'
              type='circle'
              source='prison_points'
              paint={{
                'circle-radius': [
                  "/", ['number', ['get', 'POPULATION']], 1000/viewport.zoom
                ],
                'circle-stroke-width': 2,
                'circle-stroke-color': 'red',
                'circle-stroke-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5]

              }}
              onHover={onHover}
              onLeave={onLeave}
            />
            {hoveredStateId &&
              (<Fragment>
                <FeatureState id={hoveredStateId} source='prison_points' state={{ hover: true }} />
                <FeatureState id={hoveredStateId} source='prison_boundaries' state={{ hover: true }} />
              </Fragment>)
            }
          </MapGL>
        </Grid>
      </Grid>
    </div>
  )
}

export default App;